import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { startLogout } from '../../actions/auth';
import { useSelector, useDispatch } from 'react-redux';
import useEmblaCarousel from 'embla-carousel-react'
import {
    FaComment, FaPhone, FaPhoneAlt,
    FaRegCheckCircle, FaRegClock, FaStar, FaUser, FaWhatsapp
} from 'react-icons/fa';
import Carousel from 'react-multi-carousel';
import TextField from '@mui/material/TextField'
import InputMask from 'react-input-mask'
import { useWindowDimensions } from '../../helpers/hooks';
import section1 from '../../imgs/banner-principal.webp'
import section2 from '../../imgs/img-banner3.jpg'
import fondoblog from '../../imgs/fondo.avif'
import prueba1 from '../../imgs/prueba1.webp'
import prueba2 from '../../imgs/prueba2.webp'
import prueba3 from '../../imgs/prueba3.webp'
import prueba4 from '../../imgs/prueba4.avif'
import categoria1 from '../../imgs/cat-quehacer.webp'
import categoria2 from '../../imgs/cat-negocio.webp'
import categoria3 from '../../imgs/cat-quehacer.webp'
import categoria4 from '../../imgs/cat-como-cuidarse.webp'
import logoblog from '../../imgs/sisoy-blanco.png'
import logoNegocio from '../../imgs/logo-negocio.svg'
import compartir from '../../imgs/icons/compartir-amarillo.svg'
import "../../styles/home-styles.css";
import Heart from '@react-sandbox/heart'
import { makeDELETE, makeGET, makePOST } from '../../helpers/makeRequest';
import { toast } from 'react-toastify';
import { getCookie } from '../../helpers/cookies';
import { setOpenLoginForm } from '../../actions/ui';
import svgEscuelas from '../../imgs/icons/icon-escuelas-blanco.svg'
import svgHoteles from '../../imgs/icons/icon-hotel-blanco.svg'
import svgHospitales from '../../imgs/icons/icon-doctores-blanco.svg'
import { IoRestaurant } from 'react-icons/io5';

export const InicioScreen = () => {
    const params = useParams()
    const { actualPage } = params
    const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(startLogout())
    }
    const history = useHistory()
    const [reloading, setReloading] = useState(false)
    const [page, setPage] = useState(actualPage == ':actualPage' ? 'inicio' : actualPage)
    const handleChangeOfRoute = (route) => {
        setPage(route)
        const href = window.location.href.includes("?") ? window.location.href.substring(window.location.href.indexOf("?")) : ""
        history.push('/' + route + href)
    }
    const ui = useSelector(state => state.ui);
    const { coords } = useSelector(state => state.ui);

    const elementoCargando = [{
        "categoria": "Cargando...",
        "nombre": "Cargando...",
        "estado": "Cargando...",
        "ciudad": "Cargando...",
        "coordenadas": [],
        "productos": [
            "Cargando..."
        ],
        "listadoid": 0,
        "telefono": "5555555555",
        "horarios": {},
        "pagos": [
            "Efectivo"
        ],
        "favorito": false,
        isCharging: true
    }]

    const [options, setOptions] = useState([])
    const [optionsMap, setOptionsMap] = useState([])
    const [optionSelected, setOptionSelected] = useState("")

    const [options2, setOptions2] = useState([])
    const [optionsMap2, setOptionsMap2] = useState([])
    const [optionSelected2, setOptionSelected2] = useState("")
    const [locations, setLocations] = useState([
        {
            ubicacion: "Restaurantes",
            img: prueba1,
            score: 3.5,
            favorito: true,
            lugares: "CDMX",
            route: "sinaloa/los-mochis/1"
        },
        {
            ubicacion: "Escuelas",
            img: prueba2,
            score: 5,
            favorito: false,
            lugares: "CDMX",
            route: "jalisco/puerto-vallarta/1"
        },
        {
            ubicacion: "Hoteles",
            img: prueba3,
            score: 4.2,
            favorito: false,
            lugares: "CDMX",
            route: "oaxaca/oaxaca-de-juarez/1"
        },
        {
            ubicacion: "Doctores",
            img: prueba4,
            score: 2.3,
            favorito: true,
            lugares: "CDMX",
            route: "nayarit/tepic/1"
        },
        {
            ubicacion: "Entretenimiento",
            img: prueba1,
            score: 3.5,
            favorito: false,
            lugares: "CDMX",
            route: "colima/colima/1"
        },
        {
            ubicacion: "Mecanicos",
            img: prueba2,
            score: 5,
            favorito: true,
            lugares: "CDMX",
            route: "guerrero/acapulco-de-juarez/1"
        },
        {
            ubicacion: "Ferreteria",
            img: prueba3,
            score: 4.2,
            favorito: false,
            lugares: "CDMX",
            route: "hidalgo/pachuca-de-soto/1"
        },
    ])
    const [categorias, setCategorias] = useState([
        {
            img: categoria1
        },
        {
            img: categoria2
        },
        {
            img: categoria3
        },
        {
            img: categoria4,
        },

    ])
    const [infoTop5, setInfoTop5] = useState([])
    const [loadingInfoTop5, setLoadingInfoTop5] = useState(false)

    const isOpened = (horarios) => {
        const wrapHorario = (hora) => {
            hora = String(hora)
            let split = hora.split(":")
            split.pop()
            return split.join(":")
        }
        let horariosTmp = [], horariosRange = []
        let isOpened = false
        if (horarios) {
            if (horarios?.semana) {
                horariosTmp = [...horariosTmp, "Lunes a Viernes: " + wrapHorario(horarios?.semana?.open)
                    + " - " + wrapHorario(horarios?.semana?.close)]
                horariosRange = [...horariosRange, {
                    days: [1, 2, 3, 4, 5],
                    open: parseInt((horarios?.semana?.open)?.split(":")[0]),
                    close: parseInt((horarios?.semana?.close)?.split(":")[0]),
                }]
            }
            if (horarios?.sabado) {
                horariosTmp = [...horariosTmp, "Sabado: " + wrapHorario(horarios?.sabado?.open)
                    + " - " + wrapHorario(horarios?.sabado?.close)]
                horariosRange = [...horariosRange, {
                    days: [6],
                    open: parseInt((horarios?.sabado?.open)?.split(":")[0]),
                    close: parseInt((horarios?.sabado?.close)?.split(":")[0]),
                }]
            }
            if (horarios?.domingo) {
                horariosTmp = [...horariosTmp, "Domingo: " + wrapHorario(horarios?.domingo?.open)
                    + " - " + wrapHorario(horarios?.domingo?.close)]
                horariosRange = [...horariosRange, {
                    days: [0],
                    open: parseInt((horarios?.domingo?.open)?.split(":")[0]),
                    close: parseInt((horarios?.domingo?.close)?.split(":")[0]),
                }]
            }
        }

        const currentDate = new Date()
        const finded = horariosRange.find(v => v.days.includes(currentDate.getDay()))
        if (finded?.open <= currentDate.getHours() && finded?.close >= currentDate.getHours()) {
            isOpened = true
        }
        return {
            isOpened, nextHour: ((isOpened ? finded?.close : finded?.open) + "hrs")
        }
    }

    useEffect(async () => {
        if (actualPage != undefined) return

        const permission_state = await navigator.permissions.query({ name: 'geolocation' })
        setLoadingInfoTop5(true)
        if (coords?.lat != undefined && coords?.lng != undefined && permission_state.state == "granted") {
            let { data } = await makeGET("searchByCoords?lat=" + ui?.coords?.lat + "&lng=" + ui?.coords?.lng)

            data = data.map(v => {
                return {
                    ...v,
                    horarioInfo: isOpened(v?.horarios)
                }
            })
            setInfoTop5([...data])
        } else {
            if (permission_state.state != "granted") {
                let { data } = await makeGET("searchByCoords")
                data = data.map(v => {
                    return {
                        ...v,
                        horarioInfo: isOpened(v?.horarios)
                    }
                })
                setInfoTop5([...data])
            }
        }
        setLoadingInfoTop5(false)
    }, [coords])

    const [emblaRef, emblaApi] = useEmblaCarousel({ dragFree: true })
    const [emblaRef1, emblaApi1] = useEmblaCarousel()
    const [emblaRef2, emblaApi2] = useEmblaCarousel()

    const onSlideClick = (e) => {
        !e && emblaApi.reInit({ draggable: e })
    }

    const [form, setForm] = useState({
        sitio: "0"
    })

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    }

    const responsive1 = {
        desktop: {
            breakpoint: { max: 3000, min: 768 },
            items: 5.5
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 2.5
        }
    }
    const responsive2 = {
        desktop: {
            breakpoint: { max: 3000, min: 768 },
            items: 3.5
        },
        mobile: {
            breakpoint: { max: 768, min: 0 },
            items: 1.5
        }
    }

    const returnAspect = (desk, mobile) => {
        return {
            desktop: {
                breakpoint: { max: 3000, min: 768 },
                items: desk
            },
            mobile: {
                breakpoint: { max: 768, min: 0 },
                items: mobile
            }
        }
    }

    const undReturn = (val) => {
        if (val == undefined) {
            return ""
        }
        return val
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const { height, width } = useWindowDimensions()

    const changeValue = (idx, key, value) => {
        let tmp = [...infoTop5]
        tmp[idx][key] = value

        setInfoTop5([...tmp])
    }

    const validateForm = () => {
        if (form?.nombre?.length > 0 && form?.telefono?.replace(/ /g, '')?.length == 10
            && validateEmail(form?.correo) && form?.sitio?.length > 0 &&
            form?.mensaje?.length > 0) {
            return false
        }
        return true
    }

    const blogEntries = [
        {
            titulo: "¿Qué es la madera sustentable y por qué elegirla para tus proyectos?",
            texto: "Si te dedicas a la carpintería o tienes afición a la creación de proyectos de mader...",
            svg: svgHoteles,
            txt: "¿Qué hacer?"
        },
        {
            titulo: "Actividades para los niños en Semana Santa",
            texto: "¿Quieres que tus niñas y niños pasen unas vacaciones de Sema- na Santa inolvidables y, al mismo tiempo hacer activid",
            svg: svgEscuelas,
            txt: "Tu negocio"
        },
        {
            titulo: "Mujer poeta latinoamericana, algunas recomendaciones",
            texto: "En los últimos años ha estado en constante discusión la importancia de la escritura femenina y sobre todo se han ido constr… Ver más",
            icon: <IoRestaurant style={{ fontSize: "45px" }} />,
            txt: "¿Qué comer?"
        },
        {
            titulo: "Librería de la periferia, acción contra la centralización cultural",
            texto: "¿Crees que la centralización de la cultura es un problema y que obliga a viajar largas horas desde la periferia para llegar… Ver más",
            svg: svgHospitales,
            txt: "¿Cómo cuidarse?"
        },
    ]

    return (
        <div className='container'>
            <div className='row m-0 p-0'>
                <div className='col-12 mb-4'>
                    <div className="card text-white mb-4 border-0">
                        <img className="card-img br-15" width={"100%"} height={"150px"}
                            src={section1}
                            alt="Card image" />
                        <div className="card-img-overlay d-flex flex-column justify-content-center shadow-card-right">
                            <h1 className="m-0 card-font-h1">¡Aquí encuentras <br/> lo que <span className="m-0 card-font-span"> buscas!</span></h1>
                        </div>
                    </div>
                    <div className='text-left mb-3'>
                        <span className='h4 font-weight-bolder'>Más valorado</span>
                    </div>
                    <Carousel arrows={true} autoPlay={false} responsive={responsive2} rewind={true}
                        rewindWithAnimation={true} className='mb-3'>
                        {(infoTop5.length > 0 ? infoTop5 : elementoCargando).map((v, i) => (
                            <div itemProp="name" role='button' key={i} className='ml-1 mr-3 bg-white city-post-home'>
                                <div className="card text-white border-0" onClick={(e) => {
                                    if (!v?.isCharging) {
                                        handleChangeOfRoute("informacion/" + v?.nombre.toLowerCase().replace(/ /g, '-')
                                            + "/" + v?.categoria.toLowerCase().replace(/ /g, '-') + "/" + v?.listadoid)
                                    }
                                }} >
                                    <img className="card-img br-15 aspect1"
                                        src={locations[i == 0 ? i % 4 : i]?.img}
                                        alt="Card image" />
                                    <div className="card-img-overlay text-left d-flex align-items-start flex-column shadow-card-right">
                                        <img className="mb-5" height={"20%"} src={logoNegocio} alt="Logo negocio" />
                                        <div className='mt-auto d-flex flex-column'>
                                            <span className='city-title-home'>
                                                {v.nombre}
                                            </span>
                                            <span className='card-p-home'>
                                                <FaUser />{" Nicolás Calderón"}<br />
                                            </span>
                                            <p className='card-p-home'>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut. 
                                            </p>
                                            
                                        </div>
                                        {
                                            <div className={'heart-home-div'} onClick={(e) => {
                                                if (getCookie("token")) {
                                                    if (v.favorito) {
                                                        makeDELETE("favourites/delete",
                                                            {
                                                                id_favourite: v?.favourite_info?.id_favourite
                                                            })
                                                    } else {
                                                        makePOST("favourites/add",
                                                            {
                                                                business_id: v.listadoid,
                                                                list_name: "general"
                                                            })
                                                    }
                                                    changeValue(i, "favorito", !v.favorito)
                                                } else {
                                                    dispatch(setOpenLoginForm(true))
                                                }
                                                e.stopPropagation();
                                            }}>
                                                <Heart className={!v.favorito ? "heart-home-not" : "heart-svg"}
                                                    width={24} inactiveColor='lightgrey' active={v.favorito} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                    <div className='text-left m-city-home mb-3'>
                        <span className='h4 font-weight-bolder'>Promocionado</span>
                    </div>
                    <Carousel arrows={true} autoPlay={false} responsive={returnAspect(2.2, 1.1)} rewind={true}
                        rewindWithAnimation={true} className='mb-3'>
                        {(infoTop5.length > 0 ? infoTop5 : elementoCargando).map((v, i) => (
                            <div itemProp="name" role='button' key={i} className='ml-1 mr-3 bg-white city-post-home'
                                onClick={() => {
                                    if (!v?.isCharging) {
                                        handleChangeOfRoute("informacion/" + v?.nombre.toLowerCase().replace(/ /g, '-')
                                            + "/" + v?.categoria.toLowerCase().replace(/ /g, '-') + "/" + v?.listadoid)
                                    }
                                }}>
                                <div className="card text-white border-0">
                                    <img className="card-img br-15 object-fitc" width={"728px"} height={"120px"}
                                        src={locations[i == 0 ? i % 4 : i]?.img}
                                        alt="Card image" />
                                    <div className="card-img-overlay text-left d-flex align-items-start flex-column shadow-card-right">
                                        <img height={"30%"} src={logoNegocio} alt="Logo negocio" />
                                        <div className='mt-auto text-right d-flex align-items-end flex-column'>
                                            <span className='font-weight-bolder'>Verificado
                                                {
                                                    <FaRegCheckCircle className='second-veryfybg-home' />
                                                }
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                    <div className='text-left mt-5'>
                        <span className='h4 font-weight-bolder'>Recomendado</span>
                    </div>
                    <div className="embla__viewport mb-50-desktop-home" ref={emblaRef}>
                        <div className="embla__container max-height-150-home">
                            {(infoTop5.length > 0 ? infoTop5 : elementoCargando).map((v, i) => (
                                <div className="embla__slide3 m-0 p-0 z-index-50-home"
                                    key={i} role='button'>
                                    <div key={i} className='bg-white mb-3'>
                                        <div className='row m-0 p-0 shadow-card mr-3 mt-3 ml-2'>
                                            <div className='col-6 pl-0'>
                                                <div className='card-img-top position-relative'>
                                                    {
                                                        <FaRegCheckCircle className='second-veryfybg-home' />
                                                    }

                                                    <img className="w-100 img-top-home" key={i}
                                                        onClick={() => {
                                                            if (!v?.isCharging) {
                                                                handleChangeOfRoute("informacion/" + v?.nombre.toLowerCase().replace(/ /g, '-')
                                                                    + "/" + v?.categoria.toLowerCase().replace(/ /g, '-') + "/" + v?.listadoid)
                                                            }
                                                        }}
                                                        src={locations[i == 0 ? i % 4 : i]?.img} alt="Card image cap" />
                                                </div>
                                            </div>
                                            <div className='col-6 pl-0 pt-3 pb-3' onTouchStart={(e) => onSlideClick(true)}>
                                                <div className='text-left'>
                                                    <div onClick={() => {
                                                        if (!v?.isCharging) {
                                                            handleChangeOfRoute("informacion/" + v?.nombre.toLowerCase().replace(/ /g, '-')
                                                                + "/" + v?.categoria.toLowerCase().replace(/ /g, '-') + "/" + v?.listadoid)
                                                        }
                                                    }}>
                                                        <span className="card-title p-0 m-0 h5 wrapText">{v?.nombre}</span>
                                                        {v?.distancia ?
                                                            <p className="card-text m-0 fs-12">
                                                                A {v?.distancia} km de ti
                                                            </p> : <></>
                                                        }
                                                        <p className='p-0 m-0 font-star-home'>
                                                            <FaStar color="yellow" /> {v?.score}<br />
                                                        </p>
                                                        <span className='reviews-home'> (23 reviews)</span><br />
                                                        {JSON.stringify(v?.horarios) != "{}" &&
                                                            (v?.horarioInfo?.isOpened ?
                                                                <>
                                                                    <FaRegClock />&nbsp; <span className='green-home'>Abierto
                                                                    </span>
                                                                </> :
                                                                <>
                                                                    <FaRegClock />&nbsp; <span className='text-danger'>Cerrado
                                                                    </span>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                    {
                                                        <div className={'heart-div-section'}>
                                                            <Heart className={!v.favorito ? "heart-home-not" : "heart-svg"}
                                                                width={24} inactiveColor='lightgrey' active={v.favorito} onClick={() => {
                                                                    if (v?.isCharging) return
                                                                    if (getCookie("token")) {
                                                                        if (v.favorito) {
                                                                            makeDELETE("favourites/delete",
                                                                                {
                                                                                    id_favourite: v?.favourite_info?.id_favourite
                                                                                })
                                                                        } else {
                                                                            makePOST("favourites/add",
                                                                                {
                                                                                    business_id: v.listadoid,
                                                                                    list_name: "general"
                                                                                })
                                                                        }
                                                                        changeValue(i, "favorito", !v.favorito)
                                                                    } else {
                                                                        dispatch(setOpenLoginForm(true))
                                                                    }
                                                                }} />
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='text-left m-city-home mb-3'>
                        <span className='h4 font-weight-bolder'>Notas relacionadas</span>
                    </div>
                    <Carousel arrows={true} autoPlay={false} responsive={responsive1} rewind={true}
                        rewindWithAnimation={true}>
                        {locations.map((v, i) => (
                            <div itemProp="name" role='button' key={i} className='ml-1 mr-3 bg-white city-post-home'
                                onClick={() => {
                                    window.location.replace(window.location.origin + "/resultados//" + v.route)
                                }}>
                                <div className="card text-white border-0">
                                    <img className="card-img br-15 aspect1"
                                        src={v.img}
                                        alt="Card image" />
                                    <div className="card-img-overlay text-right d-flex align-items-end flex-column shadow-card-right">
                                        <div className='mt-auto d-flex flex-column'>
                                            <span className='city-title-home'>
                                                {v.ubicacion.split(", ")[0]}
                                            </span>
                                            <span className='city-p-home'>
                                                {v.lugares}<br />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                    {/*SECCION BLOG DESKTOP*/}
                    {width > 768 &&
                        <div className='row mt-50'>
                            <div className='col-12 d-flex ml-3 mb-3'>
                                <div>
                                    <p className='h4 p-0 m-0'>¿Ya conoces</p>
                                    <p className='h1 p-0 m-0' style={{ fontWeight: "800" }}>nuestro</p>
                                </div>
                                <div className='d-flex align-items-end ml-2'>
                                    <p className='display-4 text-warning p-0 m-0' style={{ fontWeight: "800" }}>
                                        Blog<span className='font-weight-bold h2 p-0 m-0 text-dark'>?</span>
                                    </p>
                                </div>
                            </div>
                            <div className='col-7'>
                                <p className='h5 font-weight-bold'>Secciones</p>
                                <div className='row'>
                                    {blogEntries.map((v, i) => (
                                        <div itemProp="name" role='button' className='col-6 bg-white mb-3'
                                            onClick={() => { }}>
                                            <div className="card text-white border-0">
                                                <img className="card-img br-15 object-fitc" width={"100%"}
                                                    src={categorias[i % 4]?.img}
                                                    alt="Card image" />
                                                <div className="card-img-overlay text-center d-flex align-items-center justify-content-center shadow-card-right">
                                                    <div className='d-flex justify-content-center flex-column'>
                                                        {
                                                            v.svg ? <img src={v.svg} style={{ margin: "auto" }} width={"45px"} /> :
                                                                <div>
                                                                    {v.icon}
                                                                </div>
                                                        }
                                                        <span className='font-weight-bolder'>
                                                            {v.txt}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    }
                                </div>
                                <div>
                                    <p className='h5 font-weight-bold mt-4'>Te podría interesar</p>
                                    {blogEntries.slice(0, 3).map((v, i) => (
                                        <div key={i} className='bg-white w-100 mb-2'>
                                            <div className='row m-0 p-0'>
                                                <div className='col-5 m-0 p-0'
                                                >
                                                    <div className='card-img-top  position-relative'>
                                                        {
                                                            <div className='heart-div-section-white'>
                                                                <Heart className={!v.favorito ? "heart-home-not" : "heart-svg"}
                                                                    width={24} inactiveColor='lightgrey' active={v.favorito} onClick={() => { }} />
                                                            </div>
                                                        }
                                                        <img itemProp="image" className="w-100 p-1 aspect-image-result" key={i}
                                                            onClick={() => { }}
                                                            src={locations[i % 4]?.img} alt="Cambiar por el nombre de la imagen" />
                                                    </div>
                                                </div>
                                                <div className='col-7 p-0 pl-2'
                                                    onClick={() => { }}>
                                                    <div className='text-left'>
                                                        <p itemScope itemType="https://schema.org/PostalAddress"
                                                            className="card-text m-0 h4 font-weight-bold wrapText-3">
                                                            <span itemProp="addressRegion">{v.titulo}</span>
                                                        </p>
                                                        {true &&
                                                            <>
                                                                <p className="fs-16 m-0 wrapText mt-2 mb-3">
                                                                    {v.texto}
                                                                </p>
                                                                <a href="https://blogseccion.amarilio.net/" className='fs-16 text-dark font-weight-bold'>Ver más</a>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='col-5 mt-4 mb-4 text-left'>
                                <Carousel arrows={false} autoPlay={false} responsive={responsive} rewind={true}
                                    rewindWithAnimation={true} className='carousel'
                                    afterChange={(previousSlide, { currentSlide, onMove }) => {
                                    }}>
                                    {locations.map((slide, idx) => (
                                        <div key={idx} className="card text-white border-0">
                                            <img className="card-img br-15"
                                                src={fondoblog}
                                                alt="Card image" width={"300px"} height={"600px"} />
                                            <div className="card-img-overlay text-right d-flex align-items-end flex-column shadow-card-left">
                                                <img className="" height={"50px"}
                                                    src={logoblog}
                                                    alt="Logo blog" />
                                                <div className='mt-auto w-50 mb-3'>
                                                    <p className='card-font-sub-home '>
                                                        Actividades para los niños en Semana Santa
                                                    </p>
                                                    <a href="https://blogseccion.amarilio.net/" target='_blank' className="btn rounded-pill btn-yellow-black">
                                                        Leer más
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Carousel>
                                <div className='mt-5'>
                                    {blogEntries.slice(0, 3).map((v, i) => (
                                        <div key={i} className='bg-white w-100 mb-2'>
                                            <div className='row m-0 p-0'>
                                                <div className='col-5 m-0 p-0'
                                                >
                                                    <div className='card-img-top  position-relative'>
                                                        {
                                                            <div className='heart-div-section-white'>
                                                                <Heart className={!v.favorito ? "heart-home-not" : "heart-svg"}
                                                                    width={24} inactiveColor='lightgrey' active={v.favorito} onClick={() => { }} />
                                                            </div>
                                                        }
                                                        <img itemProp="image" className="w-100 p-1 aspect-image-result" key={i}
                                                            onClick={() => { }}
                                                            src={locations[i % 4]?.img} alt="Cambiar por el nombre de la imagen" />
                                                    </div>
                                                </div>
                                                <div className='col-7 p-0 pl-2'
                                                    onClick={() => { }}>
                                                    <div className='text-left'>
                                                        <p itemScope itemType="https://schema.org/PostalAddress"
                                                            className="card-text m-0 h5 font-weight-bold wrapText-3">
                                                            <span itemProp="addressRegion">{v.titulo}</span>
                                                        </p>
                                                        {true &&
                                                            <>
                                                                <p className="fs-14 m-0 wrapText mt-2 mb-3">
                                                                    {v.texto}
                                                                </p>
                                                                <a href="https://blogseccion.amarilio.net/" className='fs-14 text-dark font-weight-bold'>Ver más</a>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {/*SECCION BLOG MOVIL*/}
                {width <= 768 &&
                    <>
                        <div className='col-12 p-0 m-0 mb-4 text-left'>
                            <div className='d-flex ml-3'>
                                <div>
                                    <p className='h4 p-0 m-0'>¿Ya conoces</p>
                                    <p className='h1 p-0 m-0' style={{ fontWeight: "800" }}>nuestro</p>
                                </div>
                                <div className='d-flex align-items-end ml-2'>
                                    <p className='display-4 text-warning p-0 m-0' style={{ fontWeight: "800" }}>
                                        Blog<span className='font-weight-bold h2 p-0 m-0 text-dark'>?</span>
                                    </p>
                                </div>
                            </div>
                            <Carousel arrows={true} autoPlay={false} responsive={responsive} rewind={true}
                                rewindWithAnimation={true} className='carousel'
                                afterChange={(previousSlide, { currentSlide, onMove }) => {
                                }}>
                                {locations.map((slide, idx) => (
                                    <div key={idx} className="card text-white m-3 border-0">
                                        <img className="card-img br-15"
                                            src={fondoblog}
                                            alt="Card image" />
                                        <div className="card-img-overlay text-right d-flex align-items-end flex-column shadow-card-right">
                                            <p className="card-title m-0 card-font-home">Logo</p>
                                            <div className='mt-auto w-50 mb-3'>
                                                <p className='card-font-sub-home '>
                                                    Actividades para los niños en Semana Santa
                                                </p>
                                                <a href="https://blogseccion.amarilio.net/" target='_blank' className="btn rounded-pill btn-yellow-black">
                                                    Leer más
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                        <div className='col-12 p-0 m-0 mb-4'>
                            <p className='font-weight-bold ml-3'>Secciones</p>
                            <div className='row mr-3 ml-3'>
                                {blogEntries.map((v, i) => (
                                    <div itemProp="name" role='button' className='col-6 bg-white mb-3'
                                        onClick={() => { }}>
                                        <div className="card text-white border-0">
                                            <img className="card-img br-15 object-fitc aspect1" width={"100%"}
                                                src={locations[i % 4]?.img}
                                                alt="Card image" />
                                            <div className="card-img-overlay text-center d-flex align-items-center justify-content-center shadow-card-right">
                                                <div className='d-flex justify-content-center flex-column'>
                                                    {
                                                        v.svg ? <img src={v.svg} style={{ margin: "auto" }} width={"50px"} /> :
                                                            <div>
                                                                {v.icon}
                                                            </div>
                                                    }
                                                    <span className='font-weight-bolder'>
                                                        {v.txt}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                        <div itemProp="name" role='button' className='col-12 bg-white'
                            onClick={() => { }}>
                            <div className="card text-white border-0">
                                <img className="card-img br-15 object-fitc" width={"100%"} height={"75px"}
                                    src={locations[0]?.img}
                                    alt="Card image" />
                                <div className="card-img-overlay text-right d-flex align-items-end flex-column shadow-card-right">
                                    <div className='mt-auto d-flex flex-column'>
                                        <span className='font-weight-bolder'>
                                            Banner
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12'>
                            <p className='h5 font-weight-bold mt-4'>Te podría interesar</p>
                            {blogEntries.slice(0, 3).map((v, i) => (
                                <div key={i} className='bg-white w-100 mb-2'>
                                    <div className='row m-0 p-0'>
                                        <div className='col-5 m-0 p-0'
                                        >
                                            <div className='card-img-top  position-relative'>
                                                {
                                                    <div className='heart-div-section-white'>
                                                        <Heart className={!v.favorito ? "heart-home-not" : "heart-svg"}
                                                            width={24} inactiveColor='lightgrey' active={v.favorito} onClick={() => { }} />
                                                    </div>
                                                }
                                                <img itemProp="image" className="w-100 p-1 aspect-image-result" key={i}
                                                    onClick={() => { }}
                                                    src={locations[i % 4]?.img} alt="Cambiar por el nombre de la imagen" />
                                            </div>
                                        </div>
                                        <div className='col-7 p-0 pl-2'
                                            onClick={() => { }}>
                                            <div className='text-left'>
                                                <p itemScope itemType="https://schema.org/PostalAddress"
                                                    className="card-text m-0 h6 font-weight-bold wrapText-3">
                                                    <span itemProp="addressRegion">{v.titulo}</span>
                                                </p>
                                                {true &&
                                                    <>
                                                        <p className="fs-12 m-0 wrapText">
                                                            {v.texto}
                                                        </p>
                                                        <a href="https://blogseccion.amarilio.net/" className='fs-12 text-dark'>Ver más...</a>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                }

                <div itemScope itemType='https://schema.org/Organization'
                    className='col-12 col-md-6 mb-4 pt-3 mt-5'>
                    <div className='row'>
                        <div itemProp="address" itemScope="" itemType="http://schema.org/PostalAddress" className='col-12 mb-3'>
                            <p className='h4 font-weight-bold m-0 p-0 mt-2'>Dirección</p>
                            <p itemProp="streetAddress" className='h5 text-muted'>James Sullivan, 127, colonia San Rafael,
                                alcaldía Cuauhtémoc,<br /> CDMX, C.P. 06470.</p>
                        </div>
                        <div className='col-12 mb-3'>
                            <p className='h4 font-weight-bold m-0 p-0 mt-4'>Correo electrónico</p>
                            <p itemProp="email" className='h5 text-muted'>ventas@amarilio.com.mx</p>
                        </div>
                        <div className='col-12 mb-3'>
                            <p className='h4 font-weight-bold m-0 p-0 mt-4'>Teléfonos</p>
                            <div className='d-flex'>
                                <a itemProp="telephone" href='tel:55-3512-4586' className='h5 pr-3 text-muted'>
                                    <FaWhatsapp className='text-dark' /> 55-3512-4586
                                </a>
                                <a itemProp="telephone" href='tel:55-3512-4586' className='h5 pr-3 text-muted'>
                                    <FaPhoneAlt className='text-dark' /> 55-3512-4586
                                </a>
                            </div>
                        </div>
                        <div className='col-12 mb-3'>
                            <p className='h4 font-weight-bold m-0 p-0 mt-4'>Horarios</p>
                            <p itemProp="openingHours" className='h5 text-muted'>Lunes a viernes, de 9 a 18 horas.</p>
                        </div>
                    </div>
                </div>

                <div className='col-12 col-md-6 mb-4 pt-3 mb-4'>
                    <div className='position-relative'>
                        <FaComment className='section-blog-home' />
                        {/* <Slide> */}
                        <p id='conoceNuestroBlog' className='title-blog-home'>
                            Envíanos un mensaje
                        </p>
                        {/* </Slide> */}
                    </div>
                    <p className='h6 text-muted'>¿Te interesa una estrategia de marketing digital para tu empresa?</p>
                    <TextField
                        type="text"
                        fullWidth
                        onChange={(e) => {
                            setForm({ ...form, nombre: e.target.value })
                        }}
                        value={form.nombre || ""}
                        error={(form.nombre?.length == 0 && form.nombre != undefined)}
                        helperText={(form.nombre?.length == 0 && form.nombre != undefined) && "Este campo es requerido"}
                        autoComplete="off"
                        placeholder={""}
                        sx={{ input: { borderRadius: '15px' } }}
                        size="medium"
                        variant="outlined"
                        className='mb-3 mt-3'
                        label="Nombre completo"
                    />
                    <InputMask
                        mask="999 999 9999"
                        value={undReturn(form.telefono)}
                        disabled={false}
                        maskChar=" "
                        onChange={(e) => {
                            setForm({ ...form, telefono: (e.target.value) })
                        }}
                    >
                        {() => <TextField
                            type="text"
                            autoComplete="off"
                            placeholder="444 444 4444"
                            fullWidth
                            error={(form?.telefono?.replace(/ /g, '')?.length != 10 && form.telefono != undefined)}
                            helperText={(form?.telefono?.replace(/ /g, '')?.length != 10 && form.telefono != undefined) && "Este campo es requerido"}
                            value={form.telefono || ""}
                            size="medium"
                            className='mb-3'
                            variant="outlined"
                            label="Teléfono"
                        />}
                    </InputMask>
                    <TextField
                        type="text"
                        fullWidth
                        onChange={(e) => {
                            setForm({ ...form, correo: e.target.value })
                        }}
                        value={form.correo || ""}
                        autoComplete="off"
                        placeholder={""}
                        sx={{ input: { borderRadius: '15px' } }}
                        size="medium"
                        error={(!validateEmail(form.correo) && form.correo != undefined)}
                        helperText={(!validateEmail(form.correo) && form.correo != undefined) && "Formato de email no válido"}
                        className='mb-3'
                        variant="outlined"
                        label="Correo electrónico"
                    />
                    <div className='row mb-3'>
                        <div className='col-6'>
                            <p className='h6 text-muted'>¿Tienes sitio web?</p>
                        </div>
                        <div className='col-3'>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio"
                                    onClick={() => {
                                        setForm({ ...form, sitio: "1" })
                                    }}
                                    onChange={() => { }}
                                    checked={form.sitio == "1" ? true : false}
                                    style={{ accentColor: "#ffdd00" }}
                                    name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                <label className="form-check-label" htmlFor="inlineRadio1">Sí</label>
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio"
                                    onClick={() => {
                                        setForm({ ...form, sitio: "0" })
                                    }}
                                    checked={form.sitio == "0" ? true : false}
                                    onChange={() => { }}
                                    style={{ accentColor: "#ffdd00" }}
                                    name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                                <label className="form-check-label" htmlFor="inlineRadio2">No</label>
                            </div>
                        </div>
                    </div>
                    <TextField
                        type="text"
                        fullWidth
                        onChange={(e) => {
                            setForm({ ...form, mensaje: e.target.value })
                        }}
                        value={form.mensaje || ""}
                        autoComplete="off"
                        placeholder={""}
                        error={(form.mensaje?.length == 0 && form.mensaje != undefined)}
                        helperText={(form.mensaje?.length == 0 && form.mensaje != undefined) && "Este campo es requerido"}
                        sx={{ input: { borderRadius: '15px' } }}
                        size="medium"
                        className='mb-3'
                        multiline
                        rows={4}
                        variant="outlined"
                        label="Mensaje"
                    />
                    <div className='text-center'>
                        <button onClick={() => {
                            toast.success('¡Muchas gracias! Nos pondremos en contacto contigo muy pronto.', {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light"
                            })
                            setForm({
                                mensaje: undefined,
                                correo: undefined,
                                telefono: undefined,
                                sitio: 0,
                                nombre: undefined
                            })
                        }} className="btn rounded-pill btn-yellow-send" disabled={validateForm()}>
                            ENVIAR
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
